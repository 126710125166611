//Helpers
@import "bourbon";
@import "lib/rem";
@import "lib/variables";
@import 'lib/mixins';

//Base
@import "tailwindcss/base";
@import 'lib/typography';

//Components
@import "tailwindcss/components";
@import "lib/components/plyr/_plyr";
// @import "swiper/scss";
// @import 'lib/components/flickity';
@import "lib/components/box";
@import "lib/components/share";
@import 'lib/components/buttons';
@import 'lib/components/forms';
@import 'lib/components/footer';
@import 'lib/components/nav';
@import 'lib/components/product-box';
@import 'lib/components/tag';
@import 'lib/components/tabs';
@import 'lib/components/breadcrumbs';
@import 'lib/components/accountFinder';
@import 'lib/components/accordion';
@import 'lib/components/guide';
@import 'lib/components/ProductSlider';
@import 'lib/components/hamburger';
@import 'lib/components/languageSwitcher';
@import "ladda/dist/ladda-themeless.min.css";
// @import "@glidejs/glide/dist/css/glide.core.min.css";
// @import '@splidejs/splide/css/core';
@import 'lib/components/swiper';
@import "lib/components/leftRightBlocks";
@import "lib/components/eventCarousel";
@import "lib/components/needHelp";
@import "lib/components/atm-finder";
@import "lib/components/tables";


//Utilities
@import "tailwindcss/utilities";

@layer components {
  js-loading *,
  .js-loading *:before,
  .js-loading *:after {
    animation-play-state: paused !important;
  }

  [id] { scroll-margin-top: 2em; }

  [x-cloak] { display: none; }

  iframe{ max-width: 100%;}

  html { 
    @apply 
      w-screen
      max-w-full
      overflow-x-hidden;
    scroll-behavior: smooth; 
  }

  body {
    @apply
      overflow-x-hidden
      w-screen
      max-w-full;

    &.nav-open {
      @apply
        overflow-y-hidden;

      > main {
        @apply
          pointer-events-none;
      }
    }
  }

  html::before{
    //content:'xl';
    @apply
      fixed
      top-1
      left-1
      z-50
      text-white
      bg-blue
      p-1
      uppercase
      text-14
      font-bold;
      z-index: 99999;
      display:none;
      
      @screen lg+{ content: 'lg+' }
      @screen lg{ content: 'lg' }
      @screen md{ content: 'md' }
      @screen sm{ content: 'sm' }
    
  }

  .container{
    // @apply mx-auto;
    // max-width: $max-width;
    // width: 95%;
      @apply
        mx-auto
        max-w-[#{$max-width}]
        w-[95%];
  }

  .container--thin {
    max-width: 1256px;
  }
}

table {
    border: solid 1px #DDEEEE;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 50px;
    width: 100%;
}
thead th {
    background-color: #418792;
    border: solid 1px #DDEEEE;
    color: #fff;
    padding: 10px;
    text-align: left;
}
tbody td {
    border: solid 1px #DDEEEE;
    color: #333;
    padding: 10px;
}

.LegalDescription {
    &.prose {
        ul {
            @apply list-none;
            li {
                @apply
                    flex
                    flex-row
                    relative
                    items-start
                    pl-0
                    pb-4;

                &:before {
                    content: "\2022";

                    @apply
                        text-blue
                        block
                        mr-[12px];
                }
            }
        }
        h2 {
          margin-top: 40px;
        }
        h3 {
          margin-top: 40px;
        }
    }   
}

.profilePhoto {
  object-fit: cover;
}


/*************************************
* Pages
*************************************/

@import "pages/home";
@import "pages/about";
@import "pages/openAccount";
@import "pages/community";
@import "pages/careers";
@import "pages/security";
@import "pages/helpCenter";
@import "pages/events";
@import "pages/productDetail";
@import "pages/branchDetail";
@import "pages/50years";
@import "pages/sitemap";
@import "pages/path2credit";
@import "pages/flexible";
@import "pages/404";